import React from 'react';
import vg from "../assets/2.webp"
import {AiFillGoogleCircle, AiFillAmazonCircle,AiFillYoutube,AiFillInstagram} from "react-icons/ai";

const Home = () => {
  return (
    <>
      <div className="home" id='home'>
        <main>
          <h1>TechEdge</h1>
          <p>Solutin to all your problems</p>
        </main>
      </div>

      <div className="home2">
        <img src={vg} alt="Graphics" />
        <div>
          <p>
            We are your one and only solution to the teach problems
            you face every day. We are leading teach company whose aim is to
            increase the problem solving ability in children.
          </p>
        </div>
      </div>

      <div className="home3" id='about'>
        <div>
          <h1>Who we are?</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis, natus quas sequi ab quasi quae laborum id nesciunt exercitationem vel hic debitis rerum ex earum sint qui omnis accusantium nostrum? Excepturi iste ipsum natus similique earum maxime omnis aliquam temporibus consequuntur, commodi quae deserunt? Repellendus at illum repudiandae fugit amet.</p>
        </div>
      </div>

      <div className="home4" id='brands'>
        <div>
          <h1>Brand</h1>
          <article>
            <div style={{animationDelay:"0.3s",}}>
              <AiFillGoogleCircle/>
              <p>Google</p>
            </div>
            <div style={{animationDelay:"0.5s",}}>
              <AiFillAmazonCircle/>
              <p>Amazon</p>
            </div>
            <div style={{animationDelay:"0.7s",}}>
              <AiFillInstagram/>
              <p>Instagram</p>
            </div>
            <div style={{animationDelay:"1s",}}>
              <AiFillYoutube/>
              <p>Youtube</p>
            </div>
            
          </article>
        </div>
      </div>
    </>
  )
}

export default Home
