import React from 'react'
import { HashLink } from 'react-router-hash-link'


const Nav = () => {
    return (
        <>
            <nav class="navbar navbar-expand-lg bg-body-tertiary sticky-top">
                <div className="container-fluid ">
                    <HashLink class="navbar-brand" to="/">TechEdge</HashLink>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <HashLink class="nav-link active" aria-current="page" to="/#home">Home</HashLink>
                            </li>
                            <li class="nav-item">
                                <HashLink class="nav-link" to="/#about">About</HashLink>

                            </li>
                            <li class="nav-item">
                                <HashLink class="nav-link" to="/contact">Contact</HashLink>
                            </li>
                            <li class="nav-item">
                                <HashLink class="nav-link" to="/#brands">Brands</HashLink>
                            </li>
                            <li class="nav-item">
                                <HashLink class="nav-link" to="/service">Services</HashLink>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </>
    )
}

export default Nav
