import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div>
            <h1>TechEdge</h1>
            <p>@All right reserved</p>
        </div>

        <div>
            <h5>Follow Us</h5>
            <div>
            <a href="http://www.youtube.com" target={"blank"}>Youtube</a>
            <a href="http://www.facebook.com" target={"blank"}>Facebook</a>
            <a href="http://www.Instagram.com" target={"blank"}>Instagram</a>
            </div>
        </div>
    </footer>
  )
}

export default Footer