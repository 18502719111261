import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img1 from "../assets/3.jpg";
import img2 from "../assets/4.jpg";
const Service = () => {
  return (
    <div className="service">
     
    <Carousel infiniteLoop autoPlay showArrows={false} showStatus={false} interval={2000} showThumbs={false}>
    <div>
      <img src={img1} alt="item1" />
      <p className='legend'>Full stack</p>
    </div>

    <div>
      <img src={img2} alt="item2" />
      <p className='legend'>Full stack app developer</p>
    </div>

    </Carousel>
     
    </div>
  )
}

export default Service