import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; 
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Nav from './components/Nav';
import Footer from './components/Footer';
import Service from './components/Service';


// styling
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import "./style/App.scss"
import "./style/Nav.scss"
import "./style/Home.scss"
import "./style/Footer.scss"
import "./style/contact.scss"
import "./style/services.scss"
import "./style/mediaquery.scss"



function App() {
  return (
    <>
      <Router>
        <Nav />
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/service" element={<Service />} />
            <Route exact path="*" element={<div><h1>page not found 404</h1></div>} />
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
